import React from 'react';
import "./test-options.css";
import "../../../../helpers/shared-css/global-text-styling.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../helpers";
import Tooltip from "../../../../helpers/shared-components/tool-tip/tool-tip";

const notIncluded = ["Detailed Feedback Report", "Leaderboard access", "Unlock referral code"];

const TestOptions = ({ generalTableData, setSelectedTestId, setSelectedTestNumber }) => {
    const navigate = useNavigate();

    const practiceTestsPurchased = generalTableData.testsPurchased.filter(test => {
        if (test.id === 'Practice test') return test;
    }) ?? [];

    const tests = [
        {
            testId: "IBIS™ 100",
            testName: "IBIS100",
            price: "EUR 20.00",
            includes: ["IBIS Certificate (score > 80%)", "100 IB Technical Questions", "Detailed Feedback Report", "Leaderboard access", "Unlock referral code"],
            color: "blue",
            border: "3px solid blue",
            buttonBackground: "linear-gradient(to right, blue 0%, blue 50%, rgb(120, 120, 249) 100%)",
            buttonTextColor: "white",
        },
        {
            testId: "Practice test",
            testName: "IBIS100 (Practice test)",
            price: "Free",
            includes: ["6 Practice Questions", "Answers provided", "Detailed Feedback Report", "Leaderboard access", "Unlock referral code"],
            color: "grey",
            border: "3px solid #ECEEF3",
            buttonBackground: "white",
            buttonTextColor: "blue",
        }
    ]

    const ibis100Navigation = () => {
        const ibisTestsPurchased = generalTableData.testsPurchased.filter(test => {
            if (test.id === 'IBIS100') return test;
        }) ?? [];
        if (ibisTestsPurchased.length > 0) {
            setSelectedTestNumber(ibisTestsPurchased[0].testNumber);
            setSelectedTestId("IBIS100");
            const params = new URLSearchParams({
                previousUrl: 'home',
            });
            navigate(`${Paths.TestPortal}?${params.toString()}`);
        } else {
            navigate(Paths.BookATest);
        }
    }
    const practiceTestNavigation = () => {
        setSelectedTestNumber(practiceTestsPurchased[0].testNumber);
        setSelectedTestId("Practice test");
        const params = new URLSearchParams({
            previousUrl: 'home',
        });
        navigate(`${Paths.TestPortal}?${params.toString()}`);
    }

    return (
        <div className="small-test-options-root">
            {
                tests.map(test => {
                    return (
                        <div className="small-test-option" style={{ border: test.border }}>
                            <div className="small-test-option-top">
                                <h1 className="small-test-option-id" style={{ color: test.testId === "IBIS™ 100" ? "#0412F5" : "" }}>{test.testId}</h1>
                                <Tooltip text="This is a tooltip!">
                                    <p className="small-test-performance-information" style={{marginTop: 10}}>i</p>
                                </Tooltip>
                            </div>
                            <div className="small-test-option-middle">
                                {
                                    test.includes.map(text => {
                                        const icon = notIncluded.includes(text) && test.testId === "Practice test" ? "not-certified.png" : "certified.png";

                                        return (
                                            <div className="small-test-option-point">
                                                <img src={`/dashboard-icons/${icon}`} />
                                                <p className="small-text-style" style={{ color: notIncluded.includes(text) && test.testId === "Practice test" ? "#d9dadc" : "" }}>{text}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="small-test-option-price">
                                <h1 className="small-test-price">{test.price}</h1>
                            </div>
                            {
                                test.testId === "Practice test" && practiceTestsPurchased.length === 0 ?
                                    <p className="completed-practice-test-label">COMPLETED</p>
                                    :
                                    <button
                                        className="small-test-button"
                                        style={{ background: test.testId === "IBIS™ 100" ? "#0412F5" : "#ECEEF3", color: test.testId === "IBIS™ 100" ? "white" : "" }}
                                        onClick={test.testId === "IBIS™ 100" ? ibis100Navigation : practiceTestNavigation}>
                                        Get Started
                                    </button>
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default TestOptions;
