import React from "react";
import "./ibis-loader.css";
import { BarLoader } from "react-spinners";

const IbisLoader = () => {
    return (
        <div className="ibis-loading">
            <BarLoader 
                color="#0412F5"
                speedMultiplier={0.7}
                width="100%"
            />
        </div>
    );
};

export default IbisLoader;
