import React, { useState, useEffect } from "react";
import "./heading.css";

const Heading = () => {

    return (
        <div className="heading-root">
            <h1 className="dashboard-heading">Dashboard</h1>
        </div>
    );
};

export default Heading;
