import React, { useState } from "react";
import "./register-user.css";
import { getNames } from "country-list";
import Spinner from "../spinner/spinner";
import { fetchRequest } from "../helpers/fetch-function";
import ErrorFlag from "../error-flag/error-flag";
import { CiLogout } from "react-icons/ci";
import "../helpers/shared-css/dashboard-styling.css";
import { IoNavigateOutline } from "react-icons/io5";
import { find } from "world-universities-search";
import { isProfane } from 'no-profanity';

const countries = getNames();

const RegisterUser = ({ setInError, inError, idToken, signOut }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUniversityDropDown, setShowUniversityDropDown] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries); // State for filtered countries
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [labelStyle, setLabelStyle] = useState({});
  const [showWarning, setShowWarning] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [possibleUniversities, setPossibleUniversities] = useState([]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setLabelStyle({});
    }
  };

  const handleEmailCheckboxChange = (event) => {
    setIsEmailChecked(event.target.checked);
    if (event.target.checked) {
      setLabelStyle({});
    }
  };

  const handleLogoutClick = async () => {
    if (signOut) {
      await signOut();
    }
  };

  const handleCountryInputChange = (event) => {
    setShowWarning(undefined);
    const input = event.target.value;
    setSelectedCountry(input);
    setShowDropdown(true);

    const filtered = countries.filter((country) =>
      country.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  const handleUsernameInput = () => {
    setShowWarning(undefined);
  }

  const handleFullNameInput = () => {
    setShowWarning(undefined);
  }

  const handleSelectUniversity = (uni) => {
    setShowUniversityDropDown(false);
    setSelectedUniversity(uni);
    document.getElementById('university').value = uni
  }

  const handleUniversityInput = () => {
    const university = document.getElementById('university').value;
    setSelectedUniversity(university);
    if (university === "") {
      setPossibleUniversities([]);
    } else {
      setPossibleUniversities(find(university));
    }
    setShowUniversityDropDown(true);
  }

  const handleButtonClick = async () => {
    const username = document.getElementById("username").value;
    const country = selectedCountry;
    const university = document.getElementById("university").value;
    const fullName = document.getElementById("fullName").value;
    if (username === undefined || username === "") {
      setShowWarning('Please provide a username.');
    } else if (isProfane(username)) {
      setShowWarning('The username cannot contain any profanity.');
    } else if (fullName === undefined || fullName === "") {
      setShowWarning('Please provide a full name.');
    } else if (isProfane(fullName)) {
      setShowWarning('The username cannot contain any profanity.');
    } else if (!possibleUniversities.includes(university) && university !== "Other") {
      setShowWarning('Please select a university, or enter "Other".');
    } else if (!countries.includes(country)) {
      setShowWarning('Invalid country entered.');
    } else if (!isChecked) {
      setShowWarning('Please check the required box to register.');
    } else {
      try {
        setIsLoading(true);
        const response = await fetchRequest(idToken, "registerUser", "POST", {
          username: username,
          country: country,
          university: university,
          fullName: fullName,
        });

        if (response.status === 409) {
          const usernameTakenResponse = await response.json();
          setShowWarning(`The username, ${usernameTakenResponse.username}, has already been chosen.`);
        } else if (response.status === 200) {
          window.location.reload();
        } else {
          setInError(true);
        }
      } catch (error) {
        setInError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="register-user">
      <div className="register-div">
        <div className="register-component">
          <div className="dashboard-text-header-div-styling">
            {inError ? <ErrorFlag setInError={setInError} /> : null}
            <h3 className="dashboard-text-h3-styling">Register</h3>
            <p className="dashboard-text-p-styling">Please fill in the required information to continue and use the IBIS platform.</p>
          </div>
          <div className="register-inputs">
            <div>
              <label htmlFor="username">
                <span className="star">*</span>Username:
              </label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                placeholder="Username" 
                onChange={handleUsernameInput} 
                autocomplete="off"
                required 
              />
            </div>
            <div>
              <label htmlFor="fullName">
                <span className="star">*</span>Full name:
              </label>
              <input 
                type="text" 
                id="fullName" 
                name="fullName" 
                placeholder="John Doe" 
                onChange={handleFullNameInput} 
                autocomplete="off"
                required 
              />
              <p className="dashboard-text-p-styling">This name will be visible on the IBIS Certification, ensure it is correct.</p>
            </div>
            <div>
              <label htmlFor="university">
                <span className="star">*</span>University:
              </label>
              <input 
                type="text" 
                id="university" 
                name="university" 
                autocomplete="off"
                placeholder="University name"
                value={selectedUniversity ?? null}
                onChange={handleUniversityInput} 
                required 
              />
              {showUniversityDropDown && possibleUniversities.length > 0 && (
                <ul className="country-dropdown">
                  {possibleUniversities.map((uni) => (
                    <li
                      key={uni}
                      onClick={() => handleSelectUniversity(uni)}
                    >
                      {uni}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <label htmlFor="country">
                <span className="star">*</span>Country of origin:
              </label>
              <input
                id="country-selected"
                type="text"
                autocomplete="off"
                value={selectedCountry}
                onChange={handleCountryInputChange}
                onClick={() => setShowDropdown(!showDropdown)}
                placeholder="Select a country"
              />
              {showDropdown && (
                <ul className="country-dropdown">
                  {filteredCountries.map((country) => (
                    <li
                      key={country}
                      onClick={() => handleSelectCountry(country)}
                    >
                      {country}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="consents">
            <div>
              <label className="star">*</label>
              <input
                type="checkbox"
                id="uniqueCheckboxId1"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className="consents-label"
                htmlFor="uniqueCheckboxId1"
                style={labelStyle}
              >
                I consent to IBIS using my information for results and rankings.
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="uniqueCheckboxId2"
                checked={isEmailChecked}
                onChange={handleEmailCheckboxChange}
              />
              <label
                className="consents-label"
                htmlFor="uniqueCheckboxId2"
                style={labelStyle}
              >
                I want IBIS to email me about more information on new exams,
                etc.
              </label>
            </div>
          </div>
          <div className="register-button-div">
            <button onClick={handleButtonClick}>{!isLoading ? "Submit" : <Spinner />}{!isLoading ? <IoNavigateOutline /> : null}</button>
          </div>
          {showWarning ? (
            <div className="register-warning">
              <label>*{showWarning}</label>
            </div>
          ) : null}
        </div>
      </div>
      <div className="register-logout-div">
        <CiLogout onClick={handleLogoutClick} className="register-logout" />
      </div>
    </div>
  );
};

export default RegisterUser;
