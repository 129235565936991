import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "../line-chart.css";

const AverageHistory = ({ userResults }) => {
    const chartRef = useRef();
    useEffect(() => {
        const yaxis = userResults.map((test) => test["overall-average"] / 10);

        const dates = userResults.map((test) => test["dateWritten"]);

        const width = 350;
        const height = 200;
        const margin = { top: 20, right: 20, bottom: 20, left: 25 };

        d3.select(chartRef.current).select("svg").remove();

        const svg = d3
            .select(chartRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const xScale = d3
            .scalePoint()
            .domain(dates)
            .range([margin.left, width - margin.right])
            .padding(0.5);

        const yScale = d3
            .scaleLinear()
            .domain([0, 10])
            .nice()
            .range([height - margin.bottom, margin.top])

        const xAxis = d3.axisBottom(xScale);

        const yAxis = d3
            .axisLeft(yScale)
            .tickFormat((d) => `${d}`)
            .ticks(5)

        svg
            .append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(xAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("color", "rgb(91, 88, 88)")
            .style("text-anchor", "middle");

        svg
            .append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(yAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("color", "rgb(91, 88, 88)");

        const line = d3
            .line()
            .x((d, i) => xScale(dates[i]))
            .y((d) => yScale(d))
            .curve(d3.curveMonotoneX);

        svg
            .append("path")
            .datum(yaxis)
            .attr("fill", "none")
            .attr("stroke", "rgb(255, 99, 132)")
            .attr("stroke-width", 2)
            .attr("d", line);

        const data = dates.map((date, i) => ({
            label: date,
            value: yaxis[i],
        }));

        svg
            .selectAll("circle")
            .data(data)
            .join("circle")
            .attr("cx", (d) => xScale(d.label))
            .attr("cy", (d) => yScale(d.value))
            .attr("r", 5)
            .attr("fill", "rgb(255, 99, 132)")
            .attr("stroke", "black")
            .attr("stroke-width", 0.5);

        const tooltip = d3
            .select(chartRef.current)
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("opacity", 0)
            .style("background-color", "rgba(0, 0, 0, 0.8)")
            .style("color", "white")
            .style("border-radius", "4px")
            .style("padding", "5px")
            .style("pointer-events", "none")
            .style("z-index", "10");

        svg
            .selectAll("circle")
            .on("mouseover", function (event, d) {
                tooltip.style("opacity", 1);
                const x = xScale(d.label)
                const y = yScale(d.value)
                tooltip
                    .html(`${d.value}`)
                    .attr("data-value", d.value * 10)
                    .style("left", `${x-12}px`)
                    .style("top", `${y-35}px`)
                    .style("font-size", "10px");
            })
            .on("mouseout", function () {
                tooltip.style("opacity", 0);
            });
    }, [userResults]);

    return <div ref={chartRef} style={{ position: "relative" }}></div>;
};

export default AverageHistory;