import React from 'react';
import "./test-guide.css";
import Tooltip from "../../../../helpers/shared-components/tool-tip/tool-tip";
import "../../../../helpers/shared-css/global-text-styling.css"

const TestGuide = () => {
    const testGuideLines = [
        "Study for the test using online resources and materials",
        "Take the practice test to get an understanding of the level",
        "Purchase the IBIS™ 100 test with our secure stripe integration",
        "Take the test and score > 80% to get an official certification",
        "Receive feedback report and IBIS™ certificate"
    ]
    return (
        <div className="test-guide-root">
            <div className="test-guide-top-div">
                <div className="test-guide-top">
                    <img src={"/dashboard-icons/test-guide.png"} />
                    <h1 className="test-guide-title">Test Guide</h1>
                </div>
                <Tooltip text="This is a tooltip!">
                    <p className="test-guide-information">i</p>
                </Tooltip>
            </div>
            <div className="test-guides">
                {
                    testGuideLines.map((guideline, i) => {
                        return (
                            <div className="guideline">
                                <p className="guideline-counter">{i + 1}</p>
                                <p className="small-text-style" style={{textAlign: "start"}}>{guideline}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className="test-guide-separator"></div>
            <p className="test-guide-disclaimer">The IBIS™ 100 is globally renowned for its level of difficulty. Only 15% of candidates pass the test</p>
        </div>
    );
}

export default TestGuide;
