import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "@aws-amplify/auth";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Home from "./1. home/views/home";
import BookATest from "./2. book-a-test/views/book-a-test";
import Results from "./results/views/results";
import LandingPage from "./landing-page/views/landing-page";
import awsExports from "./aws-exports";
import CustomAuthenticator from "./authenticator/custom-authenticator";
import Spinner from "./spinner/spinner";
import SideBar from "./side-bar-navigation/side-bar";
import "./App.css";
import RegisterUser from "./register-user/register-user";
import ForgotPassword from "./forgot-password/views/forgot-password";
import TestPortal from "./7. test-portal/test-portal";
import Profile from "./5. profile/views/profile";
import TestSession from "./8. test-session/views/test-session";
import AnalyticsLeaderboard from "./3a. analytics-leaderboard/views/analytics-leaderboard";
import AnalyticsStatistics from "./3b. analytics-stats/views/analytics-stats";
import PracticeTest from "./4. practice-test/views/practice-test";
import Feedback from "./6. feedback/views/feedback";
import { fetchRequest } from "./helpers";
import { Paths } from "./helpers/constants";
import { initialLoginMockData, inProgressTestMockResponse, inProgressTestMockData, leaderboardMockData } from './helpers';
import ErrorFlag from "./error-flag/error-flag";
import IbisLoader from "./ibis-loader/ibis-loader";

Amplify.configure(awsExports);

function App() {

  const [lastPath, setLastPath] = useState("/home");
  return (
    <Router>
      <div className="App">
        <Authenticator.Provider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path={Paths.Login}
              element={<LoginOrSignUp lastPath={lastPath} />}
            />
            <Route
              path={Paths.SignUp}
              element={<LoginOrSignUp lastPath={lastPath} />}
            />
            <Route
              path={Paths.ForgotPassword}
              element={<ForgotPassword lastPath={lastPath} />}
            />
            <Route
              path={Paths.Home}
              element={
                <PrivateRoute
                  component={Home}
                  setLastPath={setLastPath}
                  location={Paths.Home}
                />
              }
            />
            <Route
              path={Paths.BookATest}
              element={
                <PrivateRoute
                  component={BookATest}
                  setLastPath={setLastPath}
                  location={Paths.BookATest}
                />
              }
            />
            <Route
              path={Paths.TestPortal}
              element={
                <PrivateRoute
                  component={TestPortal}
                  setLastPath={setLastPath}
                  location={Paths.TestPortal}
                />
              }
            />
            <Route
              path={Paths.TestSession}
              element={
                <PrivateRoute
                  component={TestSession}
                  setLastPath={setLastPath}
                  location={Paths.TestSession}
                />
              }
            />
            <Route
              path={Paths.Profile}
              element={
                <PrivateRoute
                  component={Profile}
                  setLastPath={setLastPath}
                  location={Paths.Profile}
                />
              }
            />
            <Route
              path={Paths.MobileSideBarNavigation}
              element={
                <PrivateRoute
                  component={SideBar}
                  setLastPath={setLastPath}
                  location={Paths.MobileSideBarNavigation}
                />
              }
            />
            <Route
              path={Paths.AnalyticsLeaderboard}
              element={
                <PrivateRoute
                  component={AnalyticsLeaderboard}
                  setLastPath={setLastPath}
                  location={Paths.AnalyticsLeaderboard}
                />
              }
            />
            <Route
              path={Paths.AnalyticsStatistics}
              element={
                <PrivateRoute
                  component={AnalyticsStatistics}
                  setLastPath={setLastPath}
                  location={Paths.AnalyticsStatistics}
                />
              }
            />
            <Route
              path={Paths.PracticeTest}
              element={
                <PrivateRoute
                  component={PracticeTest}
                  setLastPath={setLastPath}
                  location={Paths.PracticeTest}
                />
              }
            />
            <Route
              path={Paths.Feedback}
              element={
                <PrivateRoute
                  component={Feedback}
                  setLastPath={setLastPath}
                  location={Paths.Feedback}
                />
              }
            />
          </Routes>
        </Authenticator.Provider>
      </div>
    </Router>
  );
}

const LoginOrSignUp = ({ lastPath }) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === "authenticated") {
    return <Navigate to={lastPath} />;
  }

  return (
    <div className="login-page">
      <div className="login-page-logo">
        <h1>IBIS&trade;</h1>
      </div>
      <div className="login-page-authenticator">
        <CustomAuthenticator />
      </div>
    </div>
  );
};

const PrivateRoute = ({ component: Component, setLastPath, location }) => {
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [generalTableData, setGeneralTableData] = useState({});
  const [idToken, setIdToken] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [inError, setInError] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState("");
  const [selectedTestNumber, setSelectedTestNumber] = useState("");
  const [testData, setTestData] = useState("No Value Set");
  const [allResults, setAllResults] = useState({});
  const initialAccessRef = useRef(true);

  useEffect(() => {
    const fetchUserInformation = async () => {
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken;
        setIdToken(idToken);

        const inProgressTestResponse = await fetchRequest(idToken, "getInProgressTest", "GET");
        const inProgressTestData = await inProgressTestResponse.json();
        // const inProgressTestResponse = inProgressTestMockResponse;
        // const inProgressTestData = inProgressTestMockData;
        if (inProgressTestResponse.status === 200 && inProgressTestData.results && inProgressTestData.examTimeLength) {
          const deleteInProgressTestResponse = await fetchRequest(idToken, "deleteInProgressTest", "POST", {
            results: inProgressTestData.results,
            timeTaken: inProgressTestData.examTimeLength,
            completedDate: inProgressTestData.completedDate,
            fullName: generalTableData.fullName,
          });

          if (deleteInProgressTestResponse.status === 200) {
            window.location.reload();
          } else {
            setInError(true);
          }

        } else if (inProgressTestResponse.status !== 404) {
          setInError(true);
        }

        const initialLoginResponse = await fetchRequest(
          idToken,
          "initialLogin",
          "GET",
          undefined
        );
        const initialLoginData = await initialLoginResponse.json();
        // const initialLoginResponse = {status: 200};
        // const initialLoginData = initialLoginMockData;

        if (initialLoginResponse.status !== 200) {
          setInError(true);
        }

        setGeneralTableData(initialLoginData);

        const leaderboardResponse = await fetchRequest(idToken, "getLeaderboardResults", "POST", {
          getIbis100Results: initialLoginData["testsCompleted"].some(obj => obj.id === 'IBIS100' && obj.status === "Complete"),
          getPracticeTestResults: initialLoginData["testsCompleted"].some(obj => obj.id === 'Practice test'),
          subset: 20,
          country: initialLoginData.country,
        });
        const leaderboard = await leaderboardResponse.json();
        // const leaderboardResponse = {status: 200};
        // const leaderboard = leaderboardMockData;
        setAllResults(leaderboard);

        if (leaderboardResponse.status !== 200) {
          setInError(true);
        }

      } catch (error) {
        console.error("Failed to fetch user", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user && initialAccessRef.current) {
      fetchUserInformation();
      initialAccessRef.current = false;
    } else {
      setIsLoading(false);
    }
  }, [user]);


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem("sessionId", sessionId);
      navigate(Paths.BookATest);
    }
  }, [sessionId, navigate]);

  useEffect(() => {
    const endTestSession = async () => {
      setIsLoading(true);
      try {
        const inProgressTestResponse = await fetchRequest(idToken, "getInProgressTest", "GET");
        const inProgressTestData = await inProgressTestResponse.json();
  
        if (inProgressTestResponse.status === 200 && inProgressTestData.results && inProgressTestData.examTimeLength) {
          const deleteInProgressTestResponse = await fetchRequest(idToken, "deleteInProgressTest", "POST", {
            results: inProgressTestData.results,
            timeTaken: inProgressTestData.examTimeLength,
            completedDate: inProgressTestData.completedDate,
            fullName: generalTableData.fullName,
          });
  
          if (deleteInProgressTestResponse.status === 200) {
            window.location.reload();
            navigate(Paths.Home);
          } else {
            setInError(true);
          }
        } else if (inProgressTestResponse.status !== 404) {
          setInError(true);
        }
      } catch (error) {
        console.log("Error ending test session: ", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (testData !== "No Value Set" && location !== Paths.TestSession) {
      endTestSession();
    }
  }, [testData, location, idToken, generalTableData.fullName]);


  if (inError) {
    return <ErrorFlag />;
  }

  if (location === Paths.MobileSideBarNavigation) {
    return (
      <SideBar
        signOut={signOut}
        location={location}
        username={generalTableData.username}
        mobileSideBarNavigation={true}
      />
    )
  }

  if (!user) {
    setLastPath(location);
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return <IbisLoader />;
  }

  if (idToken !== {} && generalTableData.userRegistered !== undefined) {
    if (generalTableData.userRegistered === "false") {
      return (
        <div className="login-page">
          <div className="login-page-logo">
            <h1>IBIS&trade;</h1>
          </div>
          <div className="login-page-authenticator">
            <RegisterUser
              idToken={idToken}
              setInError={setInError}
              inError={inError}
              signOut={signOut}
            />
          </div>
        </div>
      );
    }

    if (location === "/testportal") {
      // Component => TestPortal
      return (
        <Component
          idToken={idToken}
          setInError={setInError}
          inError={inError}
          generalTableData={generalTableData}
          setGeneralTableData={setGeneralTableData}
          selectedTestNumber={selectedTestNumber}
          selectedTestId={selectedTestId}
          setSelectedTestId={setSelectedTestId}
          setTestData={setTestData}
        />
      );
    }
    if (
      location === "/testportal/testsession"
    ) {
      return <Component idToken={idToken} generalTableData={generalTableData} testData={testData} setTestData={setTestData} />;
    }
    if (location === '/payment') {
      return <Component idToken={idToken} />
    }

    return (
      <main>
        <div className="app-components">
          <SideBar
            signOut={signOut}
            location={location}
            username={generalTableData.username}
            mobileSideBarNavigation={false}
          />
          <Component
            idToken={idToken}
            setInError={setInError}
            inError={inError}
            generalTableData={generalTableData}
            setGeneralTableData={setGeneralTableData}
            setSelectedTestNumber={setSelectedTestNumber}
            setSelectedTestId={setSelectedTestId}
            allResults={allResults}
          />
        </div>
      </main>
    );
  }
};

export default App;
