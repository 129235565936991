import React, { useState } from "react";
import "./test-portal.css";
import "../helpers/shared-css/dashboard-styling.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Paths } from "../helpers/constants";
import { FaTimes } from "react-icons/fa";
import Spinner from "../spinner/spinner";
import { fetchRequest } from "../helpers/fetch-function";
import { getCurrentDate } from "../helpers/get-current-date";
import PageEnteredIncorrectly from "../helpers/shared-components/page-entered-incorrectly";
import { IoNavigateOutline } from "react-icons/io5";
import { examStartedMockResponse, prepareTestMockResponse, testMockData } from '../helpers';

const TestPortal = ({
  idToken,
  setInError,
  generalTableData,
  setGeneralTableData,
  selectedTestId,
  setSelectedTestId,
  setTestData,
  selectedTestNumber,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const testId = selectedTestId;
  const validTest = testId === "" ? false : true;

  const queryParams = new URLSearchParams(location.search);
  const previousUrl = queryParams.get("previousUrl") ?? 'Home';

  const onClose = async () => {
    setSelectedTestId("");

    navigate(`/${previousUrl}`);
  };

  const onStartTestClick = async () => {
    setIsLoading(true);
    try {
      const completedDate = getCurrentDate(0);

      const examStartedResponse = await fetchRequest(idToken, "examStarted", "POST", {
        testId: testId,
        completedDate: completedDate,
        testNumber: selectedTestNumber,
      });
      // const examStartedResponse = examStartedMockResponse;

      const prepareTestResponse = await fetchRequest(idToken, "prepareInProgressTest", "POST", {
        testId: testId,
        country: generalTableData.country,
        university: generalTableData.university,
        username: generalTableData.username,
        testNumber: selectedTestNumber,
        completedDate: completedDate,
      });
      const testData = await prepareTestResponse.json();
      // const prepareTestResponse = prepareTestMockResponse;

      if (examStartedResponse.status === 200 && prepareTestResponse.status === 200) {
        setTestData(testData.testData);
        const index = 
          generalTableData.testsPurchased.findIndex(test => 
            test.id === testId && test.testNumber === selectedTestNumber
          );

        generalTableData.testsCompleted.push({ 
          id: testId, 
          completedDate: completedDate, 
          purchasedDate: generalTableData.testsPurchased[index].purchasedDate, 
          testNumber: selectedTestNumber 
        });
        generalTableData.testsCompleted = generalTableData.testsCompleted.filter((item) => 
          item.id !== "-"
        );
        setGeneralTableData(generalTableData);

        if (index !== -1) {
          generalTableData.testsPurchased.splice(index, 1);
        }

        if (generalTableData.testsPurchased.length === 0) {
          generalTableData.testsPurchased.push({ id: "-", purchasedDate: "-" });
        }
        setGeneralTableData(generalTableData);

        navigate(Paths.TestSession);
      } else {
        navigate(Paths.MyTests);
        setInError(true);
      }
    } catch (error) {
      console.error("Start test session", error);
    } finally {
      setIsLoading(false);
      setSelectedTestId("");
    }
  };

  if (!validTest) {
    return (
      <PageEnteredIncorrectly message="You have not selected a valid test. Please go to 'MyTests', 'Your Upcoming Tests' and
      select a test you want to start."/>
    );
  }

  return (
    <div className="pre-test">
      <div className="pre-test-div">
        <div className="pre-test-close-div">
          <button onClick={onClose}>
            <FaTimes className="booking-portal-close-icon" />
          </button>
        </div>
        {validTest ? (
          <div className="test-portal-information">
            <h3 className="text-h3-styling">
              You are about to begin the {testId}. Before starting your test, please read and understand the below:
            </h3>
            <div className="rules">
              {
                testId === 'IBIS100' ?
                  <p className="text-p-styling">1. You will have 100 minutes to answer 100 questions.</p>
                  :
                  <p className="text-p-styling">1. You will have 6 minutes to answer 6 questions.</p>
              }
              <p className="text-p-styling">2. All questions are multiple choice.</p>
              <p className="text-p-styling">
                3. Please ensure you have a stable internet connection during
                the test.
              </p>
              <p className="text-p-styling">
                4. Reloading the page or exiting the test will result in your
                test session being terminated and considered complete.
              </p>
              <p className="text-p-styling">
                5. Make sure you have everything prepared, clicking "Start Test"
                will put you in your test session and your test will begin.
              </p>
            </div>
            <div className="pre-test-start">
              <button onClick={!isLoading ? onStartTestClick : null}> {isLoading ? <Spinner /> : "Start test"}{!isLoading ? <IoNavigateOutline /> : null}</button>
            </div>
          </div>
        ) : (
          <div className="no-tests-available">
            You have not selected a valid test. Please go to 'MyTests', 'Your Upcoming Tests' and
            select a test you want to start.
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPortal;
