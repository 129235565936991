import React from "react";
import "./search-bar.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ generalTableData, setSelectedTestId, setSelectedTestNumber, setHighlightPurchaseTest }) => {
    const navigate = useNavigate();

    const searchChange = () => {
        const searchValue = document.getElementById('search').value;
    }

    const beginTest = () => {
        const testsPurchased = generalTableData.testsPurchased.filter(test => {
            if (test.id === 'IBIS100') return test;
        }) ?? [];

        if (testsPurchased.length > 0) {
            setSelectedTestId(testsPurchased[0].id);
            setSelectedTestNumber(testsPurchased[0].testNumber);
            const params = new URLSearchParams({
                previousUrl: 'home',
            });
            navigate(`${Paths.TestPortal}?${params.toString()}`);
        } else {
            navigate(Paths.BookATest);
        }
    }

    return (
        <div className="dashboard-heading-root">
            <input
                    className="search-bar"
                    type="text"
                    id="search"
                    placeholder="Search"
                    onChange={searchChange}
                    required
                />
            <div className="dashboard-heading-rhs">
                <button onClick={beginTest} className="begin-test-button">
                    Get Started
                </button>
                <div className="dashboard-heading-rhs-separator"></div>
                <div className="dashboard-heading-user">
                    <div className="dashboard-heading-user-user-icon"></div>
                    <div className="dashboard-heading-user-user-text">
                        <p className="dashboard-heading-user-user-text-username">{generalTableData.fullName}</p>
                        <p className="dashboard-heading-user-user-text-business">Business</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
