import React, { useState, useRef, useEffect } from "react";
import { Paths, fetchRequest, getCurrentDate } from '../../../helpers';
import { Alert, AlertTitle } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import "./payment-complete.css";
import IbisLoader from "../../../ibis-loader/ibis-loader";
import { IoNavigateOutline } from "react-icons/io5";

const PaymentComplete = ({
    idToken,
    sessionId,
    setInError,
    generalTableData,
    setGeneralTableData
}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [purchaseSuccessful, setPurchaseSuccessful] = useState(false);

    const countdownRef = useRef(40);
    const [timeout, setTimeoutState] = useState(15);
    const countdownIntervalRef = useRef(null);

    useEffect(() => {
        const getSessionStatus = async () => {
            try {
                const response = await fetchRequest(idToken, `sessionStatus?session_id=${sessionId}`, 'POST');
                if (response.status !== 200) {
                    setInError(true);
                }
                const data = await response.json();

                if (data.status === 'complete') {
                    const purchasedDate = getCurrentDate(0);

                    const testsPurchased = generalTableData.testsPurchased.filter(test => {
                        if (test.id === 'IBIS100') return test;
                    }) ?? [];
                    const testsCompleted = generalTableData.testsCompleted.filter(test => {
                        if (test.id === 'IBIS100') return test;
                    }) ?? [];

                    const testNumber = `IBIS100-0${testsCompleted.length + testsPurchased.length + 1}`;
                    const response = await fetchRequest(idToken, "purchaseExam", "POST", {
                        testId: 'IBIS100',
                        purchasedDate: purchasedDate,
                        testNumber: testNumber,
                    });

                    if (response.status === 200) {
                        let testsPurchased = [];
                        if (generalTableData?.testsPurchased) {
                            testsPurchased = generalTableData["testsPurchased"];
                        }
                        testsPurchased.push({
                            id: "IBIS100",
                            purchasedDate: purchasedDate,
                            testNumber: testNumber,
                            status: "Purchased"
                        });
                        generalTableData["testsPurchased"] = testsPurchased;
                        setGeneralTableData(generalTableData);
                        setPurchaseSuccessful(true);
                    } else {
                        setInError(true);
                    }
                } else {
                    setInError(true);
                }
            } catch (error) {
                console.error("Error fetching session status:", error);
            } finally {
                setIsLoading(false);
            }
        }
        getSessionStatus();

        return () => {
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
        };
    }, [idToken, sessionId, setInError, generalTableData, setGeneralTableData]);


    const invokeNavigation = () => {
        if (countdownIntervalRef.current) return;

        countdownRef.current = 15;
        setTimeoutState(countdownRef.current);

        countdownIntervalRef.current = setInterval(() => {
            countdownRef.current -= 1;
            setTimeoutState(countdownRef.current);

            if (countdownRef.current <= 0) {
                clearInterval(countdownIntervalRef.current);
                countdownIntervalRef.current = null;
                navigate(Paths.Home);
            }
        }, 1000);
    };


    const navigateHome = () => {
        navigate(Paths.Home);
    }


    return (
        <div className="payment-complete-root">
            {
                isLoading
                    ?
                    <IbisLoader />
                    :
                    purchaseSuccessful ?
                        <div>
                            <Alert
                                icon={<CheckCircleIcon fontSize="inherit" />}
                                severity="success"
                                style={{ maxWidth: "500px", margin: "auto", border: "1px solid rgb(186, 185, 185)" }}
                            >
                                <AlertTitle>Payment Confirmed</AlertTitle>
                                <p>Thank you! Your payment has been successfully processed. A confirmation email will be sent shortly. For any enquiries, please email admin@ibis100.com</p>
                                <p>The page will close in: <strong>{timeout}</strong></p>
                                <button className="payment-complete-button" onClick={navigateHome}>Return to home <IoNavigateOutline /></button>
                            </Alert>
                            {invokeNavigation()}
                        </div>
                        :
                        <p>Your payment has not been successfully processed.</p>
            }
        </div>
    );
};

export default PaymentComplete;
