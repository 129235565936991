import React from "react";
import "./dashboard-navigation.css";
import TestOptions from "./test-options/test-options";
import TestGuide from "./test-guide/test-guide";

const DashboardNavigation = ({ generalTableData, setSelectedTestId, setSelectedTestNumber, highlightPurchaseTest }) => {
    return (
        <div className="small-dashboard-navigation-root">
            <div className="navigation-performance-title">
                <h1>Your Tests</h1>
            </div>
            <div className="small-dashboard-navigation-steps">
                <TestOptions 
                    generalTableData={generalTableData}
                    setSelectedTestNumber={setSelectedTestNumber} 
                    setSelectedTestId={setSelectedTestId}/>
                <TestGuide />
            </div>
        </div>
    );
};

export default DashboardNavigation;
