import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./section-bar-chart.css";

const SectionBarChart = ({ userSections, globalSections, countrySections }) => {
  const chartRef = useRef();

  useEffect(() => {
    const width = 630;
    const height = 250;
    const margin = { top: 30, right: 30, bottom: 30, left: 35 };

    // Clear previous chart before drawing a new one
    d3.select(chartRef.current).select("svg").remove();

    const labels = [
      "Accounting",
      "Valuation Methods",
      "M&A and LBO",
      "Ent. Value and Eq. Value",
      "DCF",
      "Industry Multiple",
    ];

    const gs = globalSections.map((value) => parseFloat(value).toFixed(2));
    const us = userSections.map((value) => parseFloat(value).toFixed(2));
    const cs = countrySections.map((value) => parseFloat(value).toFixed(2));

    const data = [
      { label: "User", values: us },
      { label: "Country", values: cs },
      { label: "Global", values: gs },
    ];

    const x0 = d3
      .scaleBand()
      .domain(labels)
      .range([margin.left, width - margin.right])
      .padding(0.2);

    const x1 = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, x0.bandwidth()])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, 100])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.label))
      .range(["steelblue", "green", "red"]);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x0).tickSize(0))
      .selectAll("text")
      .style("font-size", "10px");

    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickFormat((d) => d + "%").ticks(5))
      .selectAll("text")
      .style("font-size", "10px");

    svg
      .selectAll(".group")
      .data(labels)
      .join("g")
      .attr("class", "group")
      .attr("transform", (d) => `translate(${x0(d)},0)`)
      .selectAll("rect")
      .data((d, i) =>
        data.map((dataset) => ({
          key: dataset.label,
          value: dataset.values[i],
          category: d,
        }))
      )
      .join("rect")
      .attr("x", (d) => x1(d.key))
      .attr("y", (d) => y(d.value))
      .attr("width", x1.bandwidth())
      .attr("height", (d) => height - margin.bottom - y(d.value))
      .attr("fill", (d) => color(d.key))
      .style("opacity", 0.8)
      .attr("stroke", "black")
      .on("mouseover", function (event, d) {
        tooltip.style("opacity", 1);
      
        const chartRect = chartRef.current.getBoundingClientRect();
      
        // Calculate the exact horizontal position based on the event and the scale
        tooltip
          .html(`${d.value}%`)
          .style(
            "left",
            `${event.clientX - chartRect.left - tooltip.node().offsetWidth / 2}px` // Relative to the chart
          )
          .style("top", `${chartRect.top + y(d.value) - 180}px`)
          .style("font-size", "10px");
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });
    
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("background-color", "rgba(0, 0, 0, 0.8)")
      .style("color", "white")
      .style("border-radius", "4px")
      .style("padding", "5px")
      .style("pointer-events", "none")
      .style("z-index", "10");

    const legend = svg
      .append("g")
      .attr("transform", `translate(${width - margin.right - 100},${margin.top})`);

    data.forEach((dataset, i) => {
      legend
        .append("rect")
        .attr("x", -490 + (i*70))
        .attr("y", 210)
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", color(dataset.label))

      legend
        .append("text")
        .attr("x", -475 + (i*70))
        .attr("y", 216)
        .text(dataset.label)
        .style("font-size", "10px")
        .style("alignment-baseline", "middle");
    });

  }, [userSections, globalSections, countrySections]);

  return <div ref={chartRef} style={{ position: "relative" }}></div>;
};

export default SectionBarChart;
