import React from "react";
import "./dashboard-test-history.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { useNavigate } from "react-router-dom";
import { Paths, getDateInWords, getDateAndTimeInWords, orderTestHistory } from "../../../helpers";

const DashboardTestHistory = ({ generalTableData, setSelectedTestId, setSelectedTestNumber }) => {
    const navigate = useNavigate();

    const testsCompleted = generalTableData.testsCompleted.filter(test => {
        if (test.id === 'IBIS100') return test;
    }) ?? [];

    const testsPurchased = generalTableData.testsPurchased.filter(test => {
        if (test.id === 'IBIS100') return test;
    }) ?? [];

    const combinedTests = [...testsPurchased, ...testsCompleted];
    const tests = orderTestHistory(combinedTests.map(test => {
        if (test["completedDate"] === undefined) {
            test["completedDate"] = "-";
        }
        if (test["purchasedDate"] === undefined) {
            test["purchasedDate"] = "-";
        }
        return test
    }));

    const navigateToTestPortal = (testNumber) => {
        setSelectedTestId("IBIS100");
        setSelectedTestNumber(testNumber);
        const params = new URLSearchParams({
            previousUrl: 'home',
        });
        navigate(`${Paths.TestPortal}?${params.toString()}`);
    }
    const navigateToResults = () => {
        navigate(Paths.AnalyticsLeaderboard);
    }

    return (
        <div className="dashboard-test-history-root">
            <div className="dashboard-test-history-top">
                <h1>Test history</h1>
            </div>
            <div className="dashboard-test-history-table">
                <div className="dashboard-test-history-heading">
                    <p>Test ID</p>
                    <p>Purchased date</p>
                    <p>Completed date</p>
                    <p>Status</p>
                    <p></p>
                </div>
                <div className="dashboard-text-history-items">
                    {
                        tests.map((test, i) => {
                            console.log(test)
                            return (
                                <div className="dashboard-text-history-item" style={{borderBottom: i+1 === tests.length ? "" : "2px solid #ECEEF3"}}>
                                    <p className="dashboard-text-history-label">{test.testNumber}</p>
                                    <p className="dashboard-text-history-label">{getDateInWords(test.purchasedDate)}</p>
                                    <p className="dashboard-text-history-label">{test.completedDate === "-" || test.status === "Pending" ? "-" : getDateAndTimeInWords(test.completedDate)}</p>
                                    <p className="dashboard-text-history-label">{test.status}</p>
                                    <p  className="dashboard-text-history-label">
                                        {
                                            test.status === "Pending" ?
                                                <p className="processing-results-label">Processing results...</p>
                                                :
                                                <button
                                                    className="test-history-button"
                                                    onClick={() => test.status === "Complete" ? navigateToResults() : navigateToTestPortal(test.testNumber)}>
                                                    {test.status === "Complete" ? "See results" : "Begin test"}
                                                </button>
                                        }
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default DashboardTestHistory;
