import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import "../line-chart.css";

const SectionHistory = ({ userResults, sections }) => {
    const chartRef = useRef();

    useEffect(() => {
        const margin = { top: 20, right: 20, bottom: 40, left: 35 };
        const width = 350;
        const height = 200;

        const dates = userResults.map((test) => test["dateWritten"]);

        const colors = ['red', 'green', 'orange', 'blue', 'yellow', 'pink'];

        const datasets = sections.map((section, i) => {
            return {
                label: section,
                data: userResults.map(result => {
                    return ({ value: result[`${section}-average`], label: result["dateWritten"] })
                }),
                color: colors[i]
            };
        });

        console.log('here: ', datasets)

        d3.select(chartRef.current).select("svg").remove();

        const svg = d3
            .select(chartRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const xScale = d3
            .scalePoint()
            .domain(dates)
            .range([margin.left, width - margin.right])
            .padding(0.5);

        const yScale = d3
            .scaleLinear()
            .domain([0, 100])
            .nice()
            .range([height - margin.bottom, margin.top]);

        const xAxis = d3.axisBottom(xScale);

        const yAxis = d3
            .axisLeft(yScale)
            .tickFormat((d) => `${d}`)
            .ticks(5);

        svg
            .append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(xAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("color", "rgb(91, 88, 88)")
            .style("text-anchor", "middle");

        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(yAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("color", "rgb(91, 88, 88)");

        const line = d3.line()
            .x((d, i) => xScale(dates[i]))
            .y(d => yScale(d));

        datasets.forEach(dataset => {
            svg.append("path")
                .datum(dataset["data"].map(d => d.value))
                .attr("fill", "none")
                .attr("stroke", dataset.color)
                .attr("stroke-width", 2)
                .attr("d", line);
        });

        const tooltip = d3
            .select(chartRef.current)
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("opacity", 0)
            .style("background-color", "rgba(0, 0, 0, 0.8)")
            .style("color", "white")
            .style("border-radius", "4px")
            .style("padding", "5px")
            .style("pointer-events", "none")
            .style("z-index", "10");

        let index = 0;
        datasets.forEach((dataset, counter) => {
            console.log('dataset: ', dataset)
            if (counter % 5 === 0 && counter > 0) {
                index += 1;
            }
            svg
                .selectAll(`circle.${dataset.label.replace(/[^a-zA-Z0-9]/g, '_')}`)
                .data(dataset.data)
                .join("circle")
                .attr("cx", d => xScale(d.label))
                .attr("cy", d => yScale(d.value))
                .attr("r", 5)
                .attr("fill", dataset.color)
                .attr("stroke", "black")
                .attr("stroke-width", 0.5)
                .on("mouseover", function (event, d, i) {
                    console.log('d: ', d)
                    tooltip.style("opacity", 1);
                    const x = xScale(d.label);
                    const y = yScale(d.value);
                    tooltip
                        .html(`${dataset.label}: ${d.value.toFixed(2)}`)
                        .style("left", `${x - 50}px`)
                        .style("top", `${y - 33}px`)
                        .style("font-size", "10px");
                })
                .on("mouseout", function () {
                    tooltip.style("opacity", 0);
                });
        });

        // Legend
        const legend = svg
            .append("g")
            .attr("transform", `translate(${width - margin.right - 150}, 10)`);

        const abbreviatedLabels = ['Accounting', 'Val. Methods', 'M&A + LBO', 'EV + EQ', 'DCF', 'Industry Mult.'];
        const spaces = [0, 60, 125, 190, 240, 275];

        datasets.forEach((dataset, i) => {
            legend.append("rect")
                .attr("x", -170 + spaces[i])
                .attr("y", 180)
                .attr("width", 8)
                .attr("height", 8)
                .attr("fill", dataset.color)
                .style("z-index", 10);

            legend.append("text")
                .attr("x", -160 + spaces[i])
                .attr("y", 187)
                .text(abbreviatedLabels[i])
                .style("font-size", "8px")
                .style("fill", "rgb(91, 88, 88)")
                .style("z-index", 10);
        });
    }, [userResults, sections]);

    return <div ref={chartRef} style={{ position: "relative" }}></div>;
};

export default SectionHistory;
