import React from "react";
import "./dashboard-performance.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import Tooltip from "../../../helpers/shared-components/tool-tip/tool-tip";

const getUserPerformanceData = (generalTableData, allResults) => {
    const ibisRanking = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.rank ?? '-' : '-';
    const ibisAverage = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.score ?? '-' : '-';
    const certified = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.certified ?? false : false;

    const userPerformance = [];
    userPerformance.push({
        text: "Certification",
        icon: certified ? "certified.png" : "not-certified.png",
        value: certified ? "Yes" : "No"
    });
    userPerformance.push({
        text: "IBIS™ Result",
        icon: "test-mark.png",
        value: ibisAverage === "-" ? "-" : `${parseFloat(ibisAverage).toFixed(2) / 10}`,
    });
    userPerformance.push({
        text: "Global Percentile",
        icon: "percentile.png",
        value: ibisRanking,
    });
    userPerformance.push({
        text: "Attempts",
        icon: "attempts.png",
        value: ibisRanking,
    });
    return userPerformance;
}

const DashboardPerformance = ({ generalTableData, allResults }) => {

    const userPerformance = getUserPerformanceData(generalTableData, allResults);

    const openCertification = () => {
        window.open(`https://ibis100-prod-certificationconstructibis100prodibis-blunnlqdmbda.s3.eu-central-1.amazonaws.com/${generalTableData.username}.html`, '_blank');
    }

    return (
        <div className="dashboard-performance-root">
            <div className="dashboard-performance-overall">
                <div className="dashboard-performance-title">
                    <h1>Your Performance</h1>
                </div>
                <div className="dashboard-performance-stats">
                    {
                        userPerformance.map(stat => {
                            return (
                                <div className="dashboard-performance-stat">
                                    <div className="dashboard-performance-header">
                                        <div className="dashboard-performance-header-text">
                                            <img src={`/dashboard-icons/${stat.icon}`} />
                                            <p className="dashboard-performance-text">{stat.text}</p>
                                        </div>
                                        <Tooltip text="This is a tooltip!">
                                            <p className="dashboard-performance-information">i</p>
                                        </Tooltip>
                                    </div>
                                    <div className="dashboard-stat-value">
                                        <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                                    </div>
                                    {
                                        stat.text === "Certification" && stat.value === "Yes" ?
                                            <div className="dashboard-ibis-certified-link" onClick={openCertification}>
                                                <p>See certification</p>
                                                <FaExternalLinkAlt />
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* <div className="dashboard-performance-overall">
                <div className="dashboard-text-header-div-styling">
                    <h3 className="dashboard-text-h3-styling">Overall performance</h3>
                    <p className="dashboard-text-p-styling">Overview of you overall IBIS100 performance</p>
                </div>
                <div className="dashboard-performance-stats">
                    {
                        overAllPerformance.map(stat => {
                            return (
                                <div className="dashboard-performance-stat">
                                    <img src={`/dashboard-icons/${stat.icon}`} />
                                    <div className="dashboard-performance-stat-information">
                                        <p className="dashboard-text-p-styling">{stat.text}</p>
                                        <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div> */}
        </div>
    );
};

export default DashboardPerformance;
