import React, { useState, useEffect } from "react";
import "./global-performance.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import Tooltip from "../../../helpers/shared-components/tool-tip/tool-tip";

const GlobalPerformance = ({ generalTableData, allResults }) => {
    const [overAllPerformance, setOverAllPerformance] = useState([
        { text: "Tests taken", icon: "tests-taken.png", value: "0" },
        { text: "Global average", icon: "average.png", value: "0" },
        { text: "Pass Rate", icon: "percentile.png", value: "0%" },
        { text: "Certificates", icon: "certified.png", value: "0" },
        { text: "Top Result", icon: "top-score.png", value: "0" },
    ]);

    console.log(allResults)

    useEffect(() => {
        if (allResults["IBIS100-Graphs"] && Object.keys(allResults["IBIS100-Graphs"]["globalResult"]).length > 0) {
            const globalResult = allResults["IBIS100-Graphs"]["globalResult"];
            const ibis100Leaderboard = allResults["IBIS100-Leaderboard"];
            setOverAllPerformance([
                { text: "Tests taken", icon: "tests-taken.png", value: globalResult["participationCount"] },
                { text: "Mean Score", icon: "average.png", value: `${parseFloat(globalResult["overall-average"]).toFixed(2) / 10}` },
                { text: "Pass Rate", icon: "percentile.png", value: `${parseFloat(globalResult["certifiedCount"] / globalResult["tests-written"]).toFixed(2)}%` },
                { text: "Certificates", icon: "certified.png", value: globalResult["certifiedCount"] },
                { text: "Top Result", icon: "top-score.png", value: parseFloat(ibis100Leaderboard[0]["score"]).toFixed(2) / 10 }
            ]);
        }
    }, [allResults])

    return (
        <div className="dashboard-performance-root">
            <div className="dashboard-performance-overall">
                <div className="global-performance-title">
                    <h1>Global statistics</h1>
                </div>
                <div className="global-performance-stats">
                    {
                        overAllPerformance.map(stat => {
                            return (
                                <div className="global-performance-stat">
                                    <div className="dashboard-performance-header">
                                        <div className="dashboard-performance-header-text">
                                            <img src={`/dashboard-icons/${stat.icon}`} />
                                            <p className="dashboard-performance-text">{stat.text}</p>
                                        </div>
                                        <Tooltip text="This is a tooltip!">
                                            <p className="global-performance-information">i</p>
                                        </Tooltip>
                                    </div>
                                    <div className="dashboard-stat-value">
                                        <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default GlobalPerformance;
