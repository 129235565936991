import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./side-bar.css";
import "../helpers/shared-css/dashboard-styling.css";
import { FaRegUserCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Paths } from "../helpers/constants";
import { IoMenu } from "react-icons/io5";
import { RiSideBarLine, RiSideBarFill } from "react-icons/ri";
import { PiArrowFatLineLeftThin, PiArrowFatLineRightThin } from "react-icons/pi";

const SideBar = ({ signOut, location, username, mobileSideBarNavigation }) => {
  const navigate = useNavigate();

  const activeLink = `#${location.split('/')[1]}`;
  const dropDownActiveLink = `#${location.split('/')[2]}` ?? "";
  const [openDropdown, setOpenDropdown] = useState(false);
  const [sideBarMinimized, setSideBarMinimized] = useState(false);

  const navigationLabels = ["GENERAL", "TOOLS", "SUPPORT"]
  const navigations = [
    [
      {
        text: "Dashboard",
        image: "dashboard.png",
        href: "home",
        path: "home",
      },
      {
        text: "IBIS 100",
        image: "test-mark.png",
        href: "booktest",
        path: "booktest",
      },
      {
        text: "Practice Test",
        image: "practice-test.png",
        href: "practiceTest",
        path: "practiceTest",
      },
      {
        text: "Results",
        image: "results.png",
        href: "results",
        path: "results",
      }
    ],
    [
      {
        text: "Leaderboards",
        image: "top-score.png",
        href: "leaderboard",
        path: "leaderboard",
      },
      {
        text: "Global Stats",
        image: "global-stats.png",
        href: "globalStats",
        path: "globalStats",
      },
      {
        text: "Referral Programme",
        image: "tests-taken.png",
        href: "referralProgramme",
        path: "referralProgramme",
      },
      {
        text: "Test Guide",
        image: "test-guide.png",
        href: "testGuide",
        path: "testGuide",
      }
    ],
    [
      {
        text: "Account Settings",
        image: "settings.png",
        href: "settings",
        path: "settings",
      },
      {
        text: "Feedback",
        image: "feedback.png",
        href: "feedback",
        path: "feedback",
      },
      {
        text: "Contact Us",
        image: "contact-us.png",
        href: "contactUs",
        path: "contactUs",
      }
    ]
  ]

  useEffect(() => {
    const specificItem = document.getElementById('analytics-li');

    if (!specificItem) return;

    const handleMouseOver = () => {
      specificItem.style.borderBottom = "1px solid gray";
      specificItem.style.borderLeft = "1px solid gray";
      specificItem.style.borderRight = "1px solid gray";
      specificItem.style.borderRadius = "5px";
      setOpenDropdown(true);
    };

    const handleMouseOut = () => {
      specificItem.style.border = "";
      setOpenDropdown(false);
    };

    specificItem.addEventListener('mouseover', handleMouseOver);
    specificItem.addEventListener('mouseout', handleMouseOut);

    return () => {
      specificItem.removeEventListener('mouseover', handleMouseOver);
      specificItem.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    navigate(`/${path}`);
  };

  const handleLogoutClick = async () => {
    if (signOut) {
      await signOut();
    }
  };

  const handleIconOnClick = () => {
    navigate(Paths.Profile);
  };

  const handleLogoOnClick = () => {
    navigate(Paths.Home)
  }

  const showSideBarNavigation = () => {
    navigate(Paths.MobileSideBarNavigation);
  }

  const changeSideBarWidth = () => {
    setSideBarMinimized(!sideBarMinimized);
  }
  console.log('ACTIVE LINK: ', activeLink);
  return (
    <div className={mobileSideBarNavigation ? "side-bar-nav" : "side-bar"} style={{ width: !mobileSideBarNavigation && sideBarMinimized ? "100px" : ""}}>
      <div className={mobileSideBarNavigation ? "side-bar-navigation" : "side-bar-mobile"}>

        <div className="user-icon-div-mobile">
          <FaRegUserCircle className="user-icon-mobile" onClick={handleIconOnClick} />
          <p>{username}</p>
        </div>
        <div className="side-bar-menu-mobile" onClick={showSideBarNavigation}>
          <IoMenu className="menu-mobile" />
        </div>
      </div>
      <div className={mobileSideBarNavigation ? "side-bar-mobile-navigation" : "side-bar-navigation"}>
          {
            sideBarMinimized ?
              <div className="logo-div">
                <PiArrowFatLineRightThin onClick={changeSideBarWidth} />
              </div>
              :
              <div className="logo-div">
                <h1>IBIS&trade;</h1>
                <PiArrowFatLineLeftThin onClick={changeSideBarWidth} style={{paddingLeft: "110px"}} />
              </div>
          }
        {
          navigations.map((navigationSet, i) => {
            return (
              <div className="side-bar-nav-set">
                <p className={sideBarMinimized ? "side-bar-nav-title-minimised" : "side-bar-nav-title"}>{navigationLabels[i]}</p>
                <div className="side-bar-nav" style={{ borderBottom: i + 1 === navigationLabels.length ? "" : "1px solid #ECEEF3"}}>
                  <ul>
                    {
                      navigationSet.map(navigation => {
                        return (
                          <li>
                            <a
                              href={`#${navigation.href}`}
                              onClick={(e) => handleLinkClick(e, navigation.path)}
                              className={activeLink === `#${navigation.href}` ?  (sideBarMinimized ? "active-minimised" : "active") : ""}
                            >
                              <p className={activeLink === `#${navigation.href}` ? (sideBarMinimized ? "nav-label-active-minimised" : "nav-label-active") : sideBarMinimized ? "nav-label-minimised" : "nav-label"}><img className={!sideBarMinimized ?"nav-label-icon" : "nav-label-icon-minimized"} src={`/dashboard-icons/${navigation.image}`} />{sideBarMinimized ? "" : navigation.text}</p>
                            </a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>

            )
          })
        }
        {/* <div className="logout-div" onClick={handleLogoutClick}>
          <button>
            <CiLogout className="logout" />
            Logout
          </button>
        </div> */}
        <div className="side-bar-bottom-logo">
          <p>@ 2024 IBIS B.V</p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
