export const orderTestHistory = (array) => {
  const statusOrder = { Purchased: 1, Pending: 2, Complete: 3 };

  const parseDate = (dateString) => {
    if (!dateString || dateString === "-") return null;
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  return array.sort((a, b) => {
    const statusComparison = (statusOrder[a.status] || 0) - (statusOrder[b.status] || 0);
    if (statusComparison !== 0) return statusComparison;

    const aCompletedDate = parseDate(a.completedDate);
    const bCompletedDate = parseDate(b.completedDate);

    if (!aCompletedDate && bCompletedDate) return -1;
    if (aCompletedDate && !bCompletedDate) return 1;
    if (!aCompletedDate && !bCompletedDate) return 0;

    return aCompletedDate - bCompletedDate;
  });
}