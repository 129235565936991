export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);

    return new Date(year, month - 1, day);
}

export const sortDates = (dateArray) => {
    return dateArray.sort((a, b) => {
        const dateA = parseDate(a);
        const dateB = parseDate(b);

        return dateA - dateB;
    });
}

export const getDateInWords = (dateString) => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const date = new Date(year, month - 1, day); // Months are 0-indexed in JS
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}

export const getDateAndTimeInWords = (dateString) => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes] = timePart.split(":").map(Number);

    const date = new Date(year, month - 1, day, hours, minutes);

    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);

    const hour12 = hours % 12 || 12;
    const amPm = hours >= 12 ? "pm" : "am";

    return `${formattedDate}, ${hour12}:${String(minutes).padStart(2, "0")}${amPm}`;
}

